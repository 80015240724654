<template>
  <div class="currency">
    <div class="currency">
      <!-- 语言设置 -->
      <!-- <div class="item" @click="showPopup">
        <div class="tit">{{ $t("setUp.language") }}</div>
        <img class="rightImg" src="../../../assets/images/right.png" alt="" />
      </div> -->
      <!-- 网络检测 -->
      <div class="item" @click="go('networkTest')">
        <div class="tit">{{ $t("setUp.netDetection") }}</div>
        <img class="rightImg" src="../../../assets/images/right.png" alt="" />
      </div>
      <!-- 在线客服 -->
      <!-- <div class="item" @click="kefu">
        <div class="tit">{{ $t("seeting.onlineService") }}</div>
        <img class="rightImg" src="../../../assets/images/right.png" alt="" />
      </div> -->
      <!-- 上传日志 -->
      <div class="item" @click="logReportToServer">
        <div class="tit">{{ $t("seeting.uploadlog") }}</div>
        <img class="rightImg" src="../../../assets/images/right.png" alt="" />
      </div>
      <!-- 帮助中心 -->
      <!-- <div class="item" @click="help">
        <div class="tit">{{ $t("seeting.help") }}</div>
        <img class="rightImg" src="../../../assets/images/right.png" alt="" />
      </div> -->
      <div class="button">
        <van-button size="large" @click="back" class="btn" type="default">{{
          $t("common.back")
        }}</van-button>
      </div>
    </div>
    <!-- 语言设置 -->
    <!-- <PopUpBottom v-model="show">
      <div class="content">
        <div class="text" @click="langChange('LANG_ZH')">
          {{ isZhLang() ? "中文" : "Chinese" }}
        </div>
        <div class="text" @click="langChange('LANG_EN')">
          {{ isZhLang() ? "英文" : "English" }}
        </div>
        <div class="text borderNone" @click="langChange('LANG_AUTO')">
          {{ isZhLang() ? "自动" : "Auto" }}
        </div>
      </div>
    </PopUpBottom> -->
  </div>
</template>

<script>
import { LANG_ZH, LANG_EN, LANG_AUTO } from "@/constant/index";
import { isZhLang, getLocalLanguage, setLocalLanguage } from "@/utils/auth";
import PopUpBottom from "../../../components/PopUpBottom";
import { getServiceUrl, helpUrl } from "@/utils/tool";
import dayjs from "dayjs";
import Logan from "logan-web";
import { getUuid } from "@/utils/auth";
import { reportLogUrl } from "@/config/index";
import { loganLog } from "@/utils/log"
export default {
  data() {
    return {
      show: false,
      logDialog: false,
      loading: false,
      type: {
        LANG_ZH,
        LANG_EN,
        LANG_AUTO,
      },
    };
  },
  components: {
    PopUpBottom,
  },
  mounted() {
    
  },
  computed: {
    userInfo() {
      console.log(this.$store.state.user.userInfo);
      return this.$store.state.user.userInfo;
    },
  },
  methods: {
    go(path) {
      this.$router.push(path);
    },
    kefu() {
      window.open(
        getServiceUrl({
          userName: this.userInfo.realName,
          conferenceNo: this.userInfo.conferenceNo,
        })
      );
    },
    async logReportToServer() {
      // this.$toast(this.$t("meeting.loging"));
      loganLog(`上传日志`)
      const { roomId, peerId } = this.$configs;

      const userId = this.$store.state.user.userId;

      const currentTimestamp = new Date().getTime();
      const endDate = dayjs(currentTimestamp).format("YYYY-MM-DD");
      const startDate = dayjs(currentTimestamp)
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      try {
        let res = await Logan.report({
          reportUrl: reportLogUrl,
          deviceId: "wechat_" + getUuid(),
          fromDayString: startDate,
          toDayString: endDate,
          customInfo: JSON.stringify({
            peerId: peerId,
            roomId: roomId,
            userId: userId,
            userName: localStorage.getItem('displayNameKey'),
            conferenceNo: localStorage.getItem('localConferenceNoKey')
          }),
          webSource: "wechat",
        });
        for (const key in res) {
          if (Object.hasOwnProperty.call(res, key)) {
            const element = res[key];
            if (element.msg !== "Report succ") {
              if (element.msg === "No log exists") {
                this.$dialog.alert({
                  confirmButtonColor: "#000",
                  message: this.$t("meeting.logFail"),
                });
              } else {
              }
            } else {
              this.$dialog.alert({
                message: this.$t("meeting.logSuccess"),
              });
            }
          }
        }
        console.log("上传日志2", res);
      } catch (error) {
        console.error("上传日志失败", error);
        this.$dialog.alert({
          message: this.$t("meeting.logFail"),
        });
      }
    },
    help() {
      window.open(helpUrl);
    },
    isZhLang() {
      return isZhLang();
    },
    langChange(e) {
      setLocalLanguage(this.type[e]);
      window.location.reload();
    },
    back() {
      this.$router.go(-1);
    },

    showPopup() {
      this.show = !this.show;
      console.log(this.show);
    },
  },
};
</script>
<style scoped lang='less'>
@dialog-message-font-size: 60px;
.currency {
  width: 100%;
  height: 100%;

  background: #fafafa;
  .rightImg {
    width: 32px;
    height: 32px;
  }
  .button {
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    margin-top: 80px;
    .btn {
      height: 88px;
    }
  }
  .content {
    background: #fff;
    width: 100%;
    border-radius: 12px 12px 16px 16px;
    .text {
      height: 96px;
      text-align: center;
      line-height: 96px;
      font-size: 32px;
      color: #333;
      border-bottom: 1px solid #eeeeee;
    }
    .borderNone {
      border: none;
    }
  }
  .item {
    background: #fff;
    margin-top: 16px;
    height: 104px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    .tit {
      font-size: 32px;
      color: #333;
    }
  }
}
</style>
